// 标签列头配置
const columns = [
    {
      field: 'rowIndex',
      headerName: '序号',
      width: 130,
      cellClass: 't-c',
        valueFormatter: ({ node: { rowIndex } }) => {
            return rowIndex + 1;
        }
    },
  
    // {
    //   field: 'ViewGCompanyName',
    //   headerName: '查看公司',
    //   editable: false,
    //   width: 250,
    //   cellClass: 't-c'
    // },
    {
      field: 'ViewContactField',
      headerName: '被查看字段',
      editable: false,
      width: 235,
      cellClass: 't-c',
      valueFormatter: ({ data: { ViewContactField } }) => {
        // 分配时间段.  周: week, 月：month， 季度: season
        let ret = ''
        switch (ViewContactField){
          case 'QQ':
            ret = 'QQ'
            break;
          case 'WeChat':
            ret = '微信'
            break;
          case 'Phone':
            ret = '固定电话'
          case 'WangWang':
            ret = '旺旺号'
            break;
          case 'Email':
            ret = '邮箱'
            break;
          case 'Fax':
            ret = '公司传真'
            break;
          case 'CompanyPhone':
            ret = '联系人电话'
            break;
        }
        return ret;
      }
    },
    {
      field: 'ViewContactName',
      headerName: '被查看联系人',
      editable: false,
      width: 235,
      cellClass: 't-c'
    },
    {
      field: 'ViewUserName',
      headerName: '查看用户',
      editable: false,
      width: 235,
      cellClass: 't-c'
    },
    {
      field: 'ViewCompanyName',
      headerName: '查看客户',
      editable: false,
      width: 235,
      cellClass: 't-c',
    },
    {
        field: 'ViewDateTime',
        headerName: '查看时间',
        editable: false,

        cellClass: 't-c',
      },
    // {
    //   field: 'operation',
    //   headerName: '操作',
    //   editable: false,
    //   cellClass: 't-c',
    //   cellRendererFramework: 'GridCellButtonRender',
    // }
  ]
  
  const isUseOptions = [
    {key: 2, value: '是'},
    {key: 1, value: '否'}
  ]
  
  export {
    columns,
    isUseOptions
  }
  