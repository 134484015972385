<!--隐私日志-->
<template>
 <div class="m-grid p-task-allocation">
    <section class="m-grid-search">
      <el-input
        class="m-grid-input"
        v-model="searchData.ViewName"
        placeholder="搜索公司名称/联系人名称"
                clearable
      ></el-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        headerHeight="35"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        :total-count="pagination.total"
        type="base"
      >
      </grid>
    </section>

    <!-- <task-config-edit-view
      ref="editView"
      @onEditConfig="onEditConfig"
    ></task-config-edit-view> -->
  </div>
</template>

<script>
import {columns} from "./modules/privacyLog/config";
import TaskConfigEditView from "./modules/taskConfig/TaskConfigEditView";
import {searchDataMap} from "./modules/privacyLog/dataMap";
import {GetprivacyLogInfo} from "@/api/config";


export default {
  name: "privacyLog",
  components: {

  },
  data() {
    return {
     columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        ViewName:""
      },
    };
  },
  methods: {
    async onSearch(page = 1) {
      const { pagination,searchData } = this;

       const sendData = {
        ...searchData,
        Page: page,
        Limit: pagination.size
      }
      const data = await GetprivacyLogInfo(sendData); // 请求接口
      console.log(data)
      pagination.page = data.Page
      pagination.total = data.Total
      this.itemSource = data.Items;

    },setOption(data){
      let option = data.map((item) => {
        return{
          key:item.ItemValue,
          value:item.ItemName
        }
      })
      return option;
    },
  },
  created() {
   this.onSearch();
  },
  mounted() {
 
  },
  watch: {},
};
</script>


